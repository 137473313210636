<template>
  <div id="purchase-manage-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">采购管理说明</span>
    </div>
    <div class="mt-20" id="desc-body">
      <Collapse v-model="module">
        <Panel name="1">
          <strong>采购列表</strong>
          <div slot="content">
            <h3 class="mt-10">1.展示采购相关信息</h3>
            <ul class="ml-20 mt-5">
              <li>点击右侧<span class="main-font-color">添加采购</span>按钮，进行采购单创建，采购单单号由系统自动生成</li>
              <li>采购单有两种结算方式：现金和记账，采购单审核通过后，将在财务采购结算模块生成相应的流水单</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%87%87%E8%B4%AD%E5%88%97%E8%A1%A8%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>
          </div>
        </Panel>

        <Panel name="2">
          <strong>采购退货</strong>
          <div slot="content">
            <h3 class="mt-10">1.展示采购退货相关信息</h3>
            <ul class="ml-20 mt-5">
              <li>暂时不支持手动创建采购退货单，现有采购退货单主要来自于采购单那边进行采购退货流程生成</li>
              <li>支持完全退货和部分退货两种，部分退货后，将有部分货品入库，需要执行出库流程</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%94%80%E5%94%AE%E9%80%80%E8%B4%A7%E5%88%97%E8%A1%A8%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

          </div>
        </Panel>
      </Collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchaseManage",
  data() {
    return {
      module: '0',
      son_module: '0',
    }
  }
}
</script>

<style scoped>
#desc-body li {
  line-height: 26px;
}
</style>
<style>
#purchase-manage-desc .ivu-collapse, #purchase-manage-desc .ivu-collapse > .ivu-collapse-item {
  border: 0;
}

#purchase-manage-desc .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  border: 0;
}

#purchase-manage-desc .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 46px;
  line-height: 46px;
  color: #2775ff;
}
</style>
