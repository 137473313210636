import { render, staticRenderFns } from "./HelpManage.vue?vue&type=template&id=18535176&scoped=true&"
import script from "./HelpManage.vue?vue&type=script&lang=js&"
export * from "./HelpManage.vue?vue&type=script&lang=js&"
import style0 from "./HelpManage.vue?vue&type=style&index=0&id=18535176&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18535176",
  null
  
)

export default component.exports