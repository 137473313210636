<template>
  <div id="complex-set-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">综合设置说明</span>
    </div>
    <div class="mt-20" id="desc-body">
      <Collapse v-model="module">
        <Panel name="1">
          <strong>单位管理</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="1-1">
                <strong>单位概况</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示单位相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>点击右侧<span class="main-font-color">更新</span>按钮，可以信息变更</li>
                    <li>为追求系统性能极致性，部分配置信息不会及时生效，点击右侧<span class="main-font-color">缓存更新</span>按钮，可以使配置信息及时生效</li>
                    <li>设置<span class="main-font-color">简称</span>字段后，简称将会展示在系统导航栏左上角</li>

                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%8D%95%E4%BD%8D%E6%A6%82%E5%86%B5%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                  <ul class="ml-20 mt-5">
                    <li>设置<span class="main-font-color">品牌</span>字段后，品牌将会在登录页面展示</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E7%99%BB%E5%BD%95%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="1-2">
                <strong>地址信息</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示单位地址相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>设置单位地址信息，支持多个地址录入</li>
                    <li>地址信息设置后，将用于采购单填写到货地址等用处</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%9C%B0%E5%9D%80%E4%BF%A1%E6%81%AF%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="2">
          <strong>仓库管理</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="2-1">
                <strong>仓库设置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置仓库相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>此页面为仓库设置页面，根据实际情况，在系统中设置相应的仓库</li>
                    <li>仓库绑定库区，仓库中存有货品库存后，仓库不可直接删除，需要先解绑库区，清去库存，才可以删除库存</li>
                     </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%BB%93%E5%BA%93%E8%AE%BE%E7%BD%AE%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="2-2">
                <strong>仓库库区设置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置仓库库区相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>将仓库分为多个库区，方便不同分类的货品的集中分类和存放</li>
                    <li>库区和库位绑定后，需要先解绑库位后，才可以删除库区</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%BB%93%E5%BA%93%E5%BA%93%E5%8C%BA%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="2-3">
                <strong>仓库库位设置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置仓库库位相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>库位作为仓库的最小单位，将仓库分成多个库区，库区分成多个库位，可以更好的存放不同分类的货品，同时更好的定位货品存放的位置，提高货品出入库的效率</li>
                    <li>库位只有当库位上的货品库存数量为0时，才可以在系统中删除该库位</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%BB%93%E5%BA%93%E5%BA%93%E4%BD%8D%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="3">
          <strong>往来单位</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="3-1">
                <strong>客户信息</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置客户信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>此页面为客户信息页面，根据实际情况，在系统中设置设置客户信息，方便在销售模块选择客户</li>
                    <li>设置好的客户信息一旦在系统销售模块使用后，仅支持修改，不支持直接删除</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%AE%A2%E6%88%B7%E4%BF%A1%E6%81%AF%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="3-2">
                <strong>供应商信息</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置供应商信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>此页面为供应商信息页面，根据实际情况，在系统中设置设置供应商信息，方便在采购模块选择供应商</li>
                    <li>设置好的供应商信息一旦在系统采购模块使用后，仅支持修改，不支持直接删除</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%BE%9B%E5%BA%94%E5%95%86%E4%BF%A1%E6%81%AF%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="3-3">
                <strong>物流商信息</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置物流商信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>此页面为供应商信息页面，用户可以将平常合作的物流商信息录入到系统，方便采购、销售模块使用</li>
                    <li>设置好的物流商信息一旦在系统使用后，仅支持修改，不支持直接删除</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E7%89%A9%E6%B5%81%E4%BF%A1%E6%81%AF%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="4">
          <strong>财务设置</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="4-1">
                <strong>流水类别</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置财务杂项结算中的流水类别</h3>
                  <ul class="ml-20 mt-5">
                    <li>类别一旦设置后，目前只支持修改，不支持删除</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E6%B5%81%E6%B0%B4%E7%B1%BB%E5%88%AB%E8%AE%BE%E7%BD%AE%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="5">
          <strong>基础参数</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="5-1">
                <strong>文件类别</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置货品文件类别</h3>
                  <ul class="ml-20 mt-5">
                    <li>货品文件类别设置后，主要用于上传文件时，选择文件分类时使用，主要为了帮助用户更好的区分文件分类</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E6%B5%81%E6%B0%B4%E7%B1%BB%E5%88%AB%E8%AE%BE%E7%BD%AE%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="6">
          <strong>账号权限</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="6-1">
                <strong>角色设置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.设置不同的角色，赋予不同角色不同的权限，分工明确，提高工作效率</h3>
                  <ul class="ml-20 mt-5">
                    <li>权限生成后，系统将对权限数据进行缓存，当重新编辑了用户角色权限，点击<span class="main-font-color">重新生效</span>按钮可以立即生效新编辑的权限</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%A7%92%E8%89%B2%E8%AE%BE%E7%BD%AE%E5%88%97%E8%A1%A8%E9%A1%B5.png" alt="">
                  </p>

                  <ul class="ml-20 mt-5">
                    <li>点击<span class="main-font-color">创建角色</span>按钮，进行角色创建</li>
                    <li>创建角色时，一个模块所有的权限都未选中时，当角色生效时，所有绑定该角色的账号都将无法看到该模块。</li>
                    <li>当对一个模块选择编辑权限，默认对该模块有查看权限，编辑权限高于查看权限</li>
                    <li>点击<span class="main-font-color">模块全选</span>按钮，可以快速选中所有模块，该角色将拥有顶级账号的权限</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%A7%92%E8%89%B2%E5%88%9B%E5%BB%BA%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="6-2">
                <strong>账号设置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.账号设置，赋予账号不同角色</h3>
                  <ul class="ml-20 mt-5">
                    <li>账号通过<span class="main-font-color">手机号</span>登陆系统，手机号<span class="main-font-color">必须</span>真实，假的手机号无法收到验证码，初始密码为手机号</li>
                    <li>页面标题右侧有提示<span class="main-font-color">剩余可用账号</span>，表示还可以创建多少个子账号</li>
                    <li>当剩余账号为0时，将不能创建子账号，需要联系客服开通子账号</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A6%E5%8F%B7%E8%AE%BE%E7%BD%AE%E5%88%97%E8%A1%A8%E9%A1%B5.png" alt="">
                  </p>
                </div>
              </Panel>


            </Collapse>
          </div>
        </Panel>
      </Collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComplexSet",
  data() {
    return {
      module: '0',
      son_module: '0',
    }
  }
}
</script>

<style scoped>
#desc-body li {
  line-height: 26px;
}
</style>
<style>
#complex-set-desc .ivu-collapse, #complex-set-desc .ivu-collapse > .ivu-collapse-item {
  border: 0;
}

#complex-set-desc .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  border: 0;
}

#complex-set-desc .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 46px;
  line-height: 46px;
  color: #2775ff;
}
</style>

