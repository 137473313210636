<template>
  <div class="layout">
    <Layout>
      <Layout>
        <Sider width="200">
          <HelpManageMenu @func="getMsgFormMenu" :active="title_select"></HelpManageMenu>
        </Sider>
        <Content>
          <FunctionDescription v-if="title_select == 2"></FunctionDescription>
          <Browser v-if="title_select == 1"></Browser>
          <GoodsManageDesc v-if="title_select == 4"></GoodsManageDesc>
          <ComplexSet v-if="title_select == 3"></ComplexSet>
          <PurchaseManage v-if="title_select == 5"></PurchaseManage>
          <SaleManage v-if="title_select == 6"></SaleManage>
          <FinancialCenter v-if="title_select == 7"></FinancialCenter>
        </Content>
      </Layout>
    </Layout>
  </div>
</template>

<script>
import HelpManageMenu from '@/components/menu/HelpManageMenu.vue';
import FunctionDescription from '@/views/help/FunctionDescription';
import Browser from '@/views/help/Browser';
import GoodsManageDesc from '@/views/help/GoodsManageDesc';
import ComplexSet from '@/views/help/ComplexSet';
import PurchaseManage from '@/views/help/PurchaseManage';
import SaleManage from '@/views/help/SaleManage';
import FinancialCenter from '@/views/help/FinancialCenter';


export default {
  name: "HelpManage",
  components: {
    HelpManageMenu,
    FunctionDescription,
    Browser,
    GoodsManageDesc,
    ComplexSet,
    PurchaseManage,
    SaleManage,
    FinancialCenter
  },
  data() {
    return {
      title_select: 1,
      menuName: "HelpManage"
    };
  },
  created() {
    this.title_select = localStorage.getItem(this.menuName) ? localStorage.getItem(this.menuName) : 1;
  },
  methods: {
    getMsgFormMenu(data) {
      this.title_select = data;
      localStorage.setItem(this.menuName, data);
    }
  },
};
</script>

<style scoped>
.ivu-layout {
  background-color: transparent;
}

.ivu-layout-sider {
  background-color: transparent;
}

.ivu-menu {
  height: 100%;
}

.laypout {
  display: flex;
}
</style>
