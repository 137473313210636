<template>
  <div id="goods-manage-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">货品管理说明</span>
    </div>
    <div class="mt-20" id="desc-body">
      <Collapse v-model="module">
        <Panel name="1">
          <strong>货品列表</strong>
          <div slot="content">
            <Collapse accordion v-model="son_module">
              <Panel name="1-1">
                <strong>货品列表</strong>
                <div slot="content">
                  <h3 class="mt-10">1.货品列表页面，包含搜索和数据表格两个模块</h3>
                  <ul class="ml-20 mt-5">
                    <li>总库存字段展示的是货品在所有仓库下的总库存，<span class="main-font-color">点击总库存数字</span>，可以显示货品在各个仓库库存明细，总库存为0时，则不展示
                    </li>
                    <li>总库存字段上的向上箭头表示总库存超过设置的库存上限</li>
                    <li>总库存字段上的向下箭头表示从库存低于设置的库存下限</li>
                    <li><span class="main-font-color">点击缩略图</span>可以展示大图</li>
                    <li><span class="main-font-color">点击货品号</span>可以显示货品明细</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E5%88%97%E8%A1%A8.png" alt="">
                  </p>

                  <h3 class="mt-10">2.列表中，点击更多可以进行货品删除和文件上传操作</h3>
                  <ul class="ml-20 mt-5">
                    <li>
                      <span class="main-font-color">点击文件上传</span>可以上传与货品相关的资料文件，单文件最大支持200MB。文件免费容量目前是<span class="main-font-color">2GB</span>，容量不够时，可以联系客服进行扩容
                    </li>
                    <li>货品删除，只有在货品<span class="main-font-color">总库存为0</span>时才可以删除，不然会提示无法删除</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E6%96%87%E4%BB%B6%E4%B8%8A%E4%BC%A0.png" alt="">
                  </p>


                </div>
              </Panel>
              <Panel name="1-2">
                <strong>创建货品</strong>
                <div slot="content">
                  <h3 class="mt-10">1.进入货品管理页面后，点击右侧按钮，进行货品创建</h3>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E5%88%97%E8%A1%A8%E6%B7%BB%E5%8A%A0%E8%B4%A7%E5%93%81.png" alt="">
                  </p>

                  <h3 class="mt-10">2.货品创建页面</h3>
                  <ul class="ml-20 mt-5">
                    <li>支持货品编号自定义</li>
                    <li>货品品类需要通过品类页面去增加品类</li>
                    <li>货品类型支持：成品、物料、半成品，<span class="main-font-color">进销存商家</span>货品类型直接选择成品</li>
                    <li>
                      库存上限和库存下限：当该商品在仓库中存储的数量超过了设定的<span class="main-font-color">库存上限</span>，或者存储的数量低于<span class="main-font-color">库存下限</span>,货品列表库存字段会用红色箭头提示用户，库存过多或多少
                    </li>
                    <li>目前只支持上传一张货品图片</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E5%88%9B%E5%BB%BA.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>
        <Panel name="2">
          <strong>货品品类</strong>
          <div slot="content">
            <h3 class="mt-10">1.货品品类将不同规格的相同的产品归属到一类，货品品类列表页面，包含搜索和数据表格两个模块</h3>
            <ul class="ml-20 mt-5">
              <li><span class="main-font-color">点击右侧添加品类</span>按钮，可以对货品品类进行新增</li>
              <li><span class="main-font-color">点击关联货品数字段的刷新图标</span>，可以刷新品类关联的货品数</li>
              <li><span class="main-font-color">点击查看</span>按钮，弹出查看页面，查看页面可以绑定货品</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E5%93%81%E7%B1%BB%E5%88%97%E8%A1%A8.png" alt="">
            </p>

            <h3 class="mt-10">2.查看页面，可以查看品类关联的货品</h3>
            <ul class="ml-20 mt-5">
              <li><span class="main-font-color">点击关联货品数字段的刷新图标</span>，可以刷新品类关联的货品数</li>
              <li><span class="main-font-color">点击关联货品</span>按钮，可以来添加品类关联的货品</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E5%93%81%E7%B1%BB%E6%9F%A5%E7%9C%8B%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>
          </div>
        </Panel>
        <Panel name="3">
          <strong>入库单</strong>
          <div slot="content">
            <h3 class="mt-10">1.入库单目前都是通过流程来创建的，暂时不支持手动创建。</h3>
            <ul class="ml-20 mt-5">
              <li>入库单来源<span class="main-font-color">采购入库、销售退货入库、调拨入库</span></li>
              <li><span class="main-font-color">点击入库单号</span>或者<span class="main-font-color">点击查看</span>按钮，都可以查看入库单详情
              </li>
              <li><span class="main-font-color">点击上架</span>按钮，可以打开上架页面，对相应的货品进行上架操作。</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%85%A5%E5%BA%93%E5%8D%95%E5%88%97%E8%A1%A8.png" alt="">
            </p>

            <h3 class="mt-10">2.点击上架按钮，打开上架页面，对货品进行上架操作</h3>
            <ul class="ml-20 mt-5">
              <li>在执行上架操作前，建议先配置好仓库、库区和库位</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%B8%8A%E6%9E%B6%E6%9F%A5%E7%9C%8B%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

            <ul class="ml-20 mt-5">
              <li>上架操作页面可以查看到仓库现有货品库存存放的位置及库存数量</li>
              <li><span class="main-font-color">选择仓库、库区及库位</span>来操作上架货品</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%85%A5%E5%BA%93%E4%B8%8A%E6%9E%B6%E6%93%8D%E4%BD%9C.png" alt="">
            </p>
          </div>


        </Panel>
        <Panel name="4">
          <strong>出库单</strong>
          <div slot="content">
            <h3 class="mt-10">1.出库单目前都是通过流程来创建的，暂时不支持手动创建。</h3>
            <ul class="ml-20 mt-5">
              <li>出库单来源<span class="main-font-color">采购退货出库、销售出库、调拨出库、领料出库</span></li>
              <li>点击<span class="main-font-color">出库单号</span>或者<span class="main-font-color">查看</span>按钮，都可以查看入库单详情</li>
              <li><span class="main-font-color">点击下架</span>按钮，可以打开下架页面，对相应的货品进行下架操作。</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%87%BA%E5%BA%93%E5%8D%95%E5%88%97%E8%A1%A8.png" alt="">
            </p>

            <h3 class="mt-10">2.点击下架按钮，打开下架页面，对货品进行下架操作</h3>
            <ul class="ml-20 mt-5">
              <li>在执行下架操作前，建议先配置好仓库、库区和库位</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%B8%8B%E6%9E%B6%E6%9F%A5%E7%9C%8B%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

            <ul class="ml-20 mt-5">
              <li>下架操作页面可以查看到仓库现有货品库存存放的位置及库存数量</li>
              <li><span class="main-font-color">选择仓库、库区及库位</span>来操作下架货品</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E4%B8%8B%E6%9E%B6%E6%93%8D%E4%BD%9C%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

            <h3 class="mt-10">3.下架完成后，则执行出库操作</h3>
            <ul class="ml-20 mt-5">
              <li>点击列表页中的<span class="main-font-color">出库按钮</span>来进行出库操作。</li>
              <li>出库后，目前出库类型为销售的则需要点击<span class="main-font-color">转发货</span>按钮，生成发货单</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%BD%AC%E5%8F%91%E8%B4%A7%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

          </div>
        </Panel>
        <Panel name="5">
          <strong>发货单</strong>
          <div slot="content">
            <h3 class="mt-10">1.发货单目前主要来源于出库单数据，暂时不支持手动创建发货单</h3>
            <ul class="ml-20 mt-5">
              <li>点击<span class="main-font-color">发货单号</span>或者<span class="main-font-color">查看</span>按钮，都可以查看发货单详情</li>
              <li>点击<span class="main-font-color">发货</span>按钮，进行发货流程操作</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%8F%91%E8%B4%A7%E5%88%97%E8%A1%A8%E9%A1%B5.png" alt="">
            </p>

            <h3 class="mt-10">2.点击发货按钮，打开发货页面</h3>
            <ul class="ml-20 mt-5">
              <li>点击<span class="main-font-color">开始发货</span>按钮，开启发货流程</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%BC%80%E5%A7%8B%E5%8F%91%E8%B4%A7%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

            <h3 class="mt-10">3.发货流程开启后，填写发货时间及物流信息</h3>
            <ul class="ml-20 mt-5">
              <li>发货时间和物流信息可不填，发货时间不填会按照当前时间作为发货时间</li>
              <li>点击<span class="main-font-color">发货完成</span>按钮，可以结束发货流程</li>
              <li>点击<span class="main-font-color">关联销售单、关联出库单</span>右侧的复制Icon,可以快速复制单号</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%8F%91%E8%B4%A7%E6%93%8D%E4%BD%9C%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>

          </div>
        </Panel>

        <Panel name="6">
          <strong>领料记录</strong>
          <div slot="content">
            <h3 class="mt-10">1.申请从仓库领料</h3>
            <ul class="ml-20 mt-5">
              <li>点击右侧<span class="main-font-color">申请领料</span>按钮，可以进行领料申请</li>
              <li>申请审核通过后，开始领料流程，将会生成出库单。</li>
              <li>领料出库完成后，需要点击<span class="main-font-color">领料完成</span>按钮，结束领料流程</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%A2%86%E6%96%99%E9%A1%B5%E9%9D%A2.png" alt="">
            </p>
          </div>
        </Panel>
        <Panel name="7">
          <strong>货品盘点</strong>
          <div slot="content">
            <h3 class="mt-10">1.系统支持货品库存盘点</h3>
            <ul class="ml-20 mt-5">
              <li>点击右侧<span class="main-font-color">创建盘点计划</span>按钮，可以进行盘点单申请</li>
              <li>盘点单审核通过后，会对相关仓库库区库存进行锁定，无法进行入库出库操作</li>
              <li>盘点完成后，点击<span class="main-font-color">盘点执行</span>按钮，可以变动系统货品库存数据，使系统和现实中的货品数同步</li>
              <li>初始录入库存数量，可以通过盘点的方式将库存数录入到系统中</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E7%9B%98%E7%82%B9%E5%88%97%E8%A1%A8.png" alt="">
            </p>
          </div>
        </Panel>
        <Panel name="8">
          <strong>库存调拨</strong>
          <div slot="content">
            <h3 class="mt-10">1.当存在多个仓库时，仓库之间相互调取货品时，则需要用到库存调拨功能</h3>
            <ul class="ml-20 mt-5">
              <li>创建调拨单时需要先选择<span class="main-font-color">源仓库</span>和<span class="main-font-color">目标仓库</span></li>
              <li>点击<span class="main-font-color">执行</span>按钮,可以自动生成类型为调拨的出库单，出库仓库为源仓库</li>
              <li>出库单出库完毕后，系统会自动更新调拨单状态，更新当前调拨流程状态，这时的调拨单状态更新为<span class="main-font-color">源库已出库</span>状态</li>
              <li>点击<span class="main-font-color">转入库</span>按钮，可以自动生成类型为调拨的入库单，调拨单状态变更为<span class="main-font-color">入库待上架</span>
              </li>
              <li>待入库单入库完毕后，调拨单状态也将会变更为<span class="main-font-color">完成</span>状态，整个调拨流程结束</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%BA%93%E5%AD%98%E8%B0%83%E6%8B%A8%E5%88%97%E8%A1%A8.png" alt="">
            </p>
          </div>
        </Panel>

        <Panel name="9">
          <strong>库存变动</strong>
          <div slot="content">
            <h3 class="mt-10">1.当仓库中的货品库存变动时，系统会自动记录变动数量，方便追述货品库存变动的痕迹</h3>
            <ul class="ml-20 mt-5">
              <li>库存变动列表中，每条记录都关联了相关的单号，方便用户去追述库存变动的痕迹</li>
              <li>备注字段记录了库存变动是在什么场景下发生的</li>
              <li>操作人代表，当库存发生变动时，操作的人员是哪个</li>
            </ul>
            <p>
              <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%BA%93%E5%AD%98%E5%8F%98%E5%8A%A8%E5%88%97%E8%A1%A8.png" alt="">
            </p>
          </div>
        </Panel>



      </Collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsManageDesc",
  data() {
    return {
      module: '0',
      son_module: '0',
    }
  }
}
</script>

<style scoped>
#desc-body li {
  line-height: 26px;
}
</style>
<style>
#goods-manage-desc .ivu-collapse, #goods-manage-desc .ivu-collapse > .ivu-collapse-item {
  border: 0;
}

#goods-manage-desc .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  border: 0;
}

#goods-manage-desc .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 46px;
  line-height: 46px;
  color: #2775ff;
}
</style>
