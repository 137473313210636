<template>
  <div id="function-description" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">使用说明</span>
    </div>

    <div>
      <Card class="mt-16" :bordered="false" v-for="item in list">
        <p slot="title" class="main-font-color">{{item.module_ch}}</p>
        <Row :gutter="16" class="mt-10" v-for="arr in item.data">
          <Col span="6" v-for="row in arr">
            <div class="h-128 main-bg-color white-font-color flex-center pointer" @click="view(row)">
              <h2>{{row.title}}</h2>
            </div>
          </Col>
        </Row>
      </Card>
    </div>

    <Modal v-model="description.modal" :title="description.title" width="750" footer-hide>
      <div style="height:500px;overflow:hidden;">
        <iframe style="width:720px;height:540px;" :src="description.video_url" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getFunctionDescriptionList,
} from '@/api/help/functionDescription';

export default {
  name: "FunctionDescription",
  data() {
    return {
      description: {
        modal: false,
        title: '使用说明',
        video_url: '',
      },
      list: []
    }
  },
  methods: {
    getMainList() {
      getFunctionDescriptionList().then((res) => {
        this.list = res.data.results;
      });
    },
    view(row) {
      this.description.video_url = row.video_url;
      this.description.title = row.title + '使用说明';
      this.description.modal = true;
    }
  },
  mounted() {
    this.getMainList();
  }
}
</script>

<style scoped>

</style>
