<template>
  <div id="sale-manage-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">销售管理说明</span>
    </div>
    <div class="mt-20" id="desc-body">
      <Collapse v-model="module">
        <Panel name="1">
          <strong>销售管理</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="1-1">
                <strong>销售列表</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示销售相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>点击右侧<span class="main-font-color">添加销售单</span>按钮，进行销售单创建，销售单单号由系统自动生成</li>
                    <li>销售单有两种结算方式：现金和记账，销售单审核通过后，将在财务销售结算模块生成相应的流水单</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%94%80%E5%94%AE%E5%88%97%E8%A1%A8%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="1-2">
                <strong>销售退货</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示销售退货相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>暂时不支持手动创建销售退货单，现有销售退货单主要来自于销售单那边进行销售退货流程生成</li>
                    <li>支持完全退货和部分退货两种，部分退货后，将有部分货品入库，需要执行入库流程</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%87%87%E8%B4%AD%E9%80%80%E8%B4%A7%E5%88%97%E8%A1%A8%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>

                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="2">
          <strong>销售报表</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="2-1">
                <strong>货品销量</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示货品销量相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据由系统每天凌晨定时计算获得</li>
                    <li>该列表展示了系统中货品的销量情况，包含总销量和当月销量</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A7%E5%93%81%E9%94%80%E9%87%8F%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="2-2">
                <strong>客户售额</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示客户销售额相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据由系统每天凌晨定时计算获得</li>
                    <li>该列表展示了系统中针对不同客户的销售额情况，包含总销售额和当月销售额</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%AE%A2%E6%88%B7%E5%94%AE%E9%A2%9D%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="3">
          <strong>售价管理</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="3-1">
                <strong>售价配置</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示针对不同客户的货品售价配置相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>用户通过该页面设置不同客户的货品售价，这边设置的货品价格将用于销售模块展示</li>
                    <li>售价配置做到了不同客户，不同货品售价</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E5%94%AE%E4%BB%B7%E9%85%8D%E7%BD%AE%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>
      </Collapse>

    </div>
  </div>
</template>

<script>
export default {
  name: "SaleManage",
  data() {
    return {
      module: '0',
      son_module: '0',
    }
  }
}
</script>

<style scoped>
#desc-body li {
  line-height: 26px;
}
</style>
<style>
#sale-manage-desc .ivu-collapse, #sale-manage-desc .ivu-collapse > .ivu-collapse-item {
  border: 0;
}

#sale-manage-desc .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  border: 0;
}

#sale-manage-desc .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 46px;
  line-height: 46px;
  color: #2775ff;
}
</style>
