<template id="menu">
  <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
    <MenuGroup title="帮助中心" >
<!--      <MenuItem name="2">-->
<!--        <Icon type="logo-dribbble"/>-->
<!--        使用说明-->
<!--      </MenuItem>-->
      <MenuItem name="1">
        <Icon type="logo-chrome" />
        浏览器推荐
      </MenuItem>
    </MenuGroup>
    <MenuGroup title="说明文档" >
      <MenuItem name="3">
        <Icon type="ios-book" />
        综合设置
      </MenuItem>
      <MenuItem name="4">
        <Icon type="ios-book" />
        货品管理
      </MenuItem>
      <MenuItem name="5">
        <Icon type="ios-book" />
        采购管理
      </MenuItem>
      <MenuItem name="6">
        <Icon type="ios-book" />
        销售管理
      </MenuItem>
      <MenuItem name="7">
        <Icon type="ios-book" />
        财务中心
      </MenuItem>
    </MenuGroup>
  </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

export default {
  name: "HelpManageMenu",
  data() {
    return {
      active_name: this.active,
    };
  },
  methods: {
    onselect(name) {
      this.$emit('func', name);
      loginUtil.setExpireTime();
    }
  },
  created() {
    this.aCheck.plateName = 'sale_manage';
  },
  props: ["active"],
};
</script>

<style scoped>
#menu {
  width: 200px;
  position: absolute;
  left: 0;
}
</style>
