<template>
  <div id="function-description" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">浏览器推荐</span>
    </div>
    <div class="mt-60">
      <Card class="flex-center" :bordered="false">
        <div style="text-align:center">
          <img src="https://www.google.cn/chrome/static/images/chrome-logo.svg" alt="" width="80">
          <span class="mt-20" style="display:block;font-size: 3.75rem;line-height: 4.5rem;letter-spacing:-.5px">由 Google 打造的浏览器</span>
          <a href="https://www.google.cn/intl/zh-CN/chrome/"target="_blank">
            <Button type="primary" shape="circle" class="mt-20" size="large">前往下载 Chrome</Button>
          </a>
        </div>
      </Card>
    </div>


  </div>
</template>

<script>
export default {
  name: "Browser"
}
</script>

<style scoped>

</style>
