<template>
  <div id="financial-center-desc" class="module-body">
    <div id="panel-header" class="panel-header">
      <span class="top-title">财务中心说明</span>
    </div>
    <div class="mt-20" id="desc-body">
      <Collapse v-model="module">
        <Panel name="1">
          <strong>财务结算</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="1-1">
                <strong>财务预览</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示财务总况相关信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>标题财务概览右侧的<span class="main-font-color">账户总额、账户现金、记账金额</span>，表示的是系统记录的该账号的所有金额往来数据汇总</li>
                    <li><span class="main-font-color">账户总额、账户现金、记账金额</span>显示的数据非实时计算出来的，有一个小时左右的延时，点击右侧的<span class="main-font-color"><Icon type="ios-refresh" /></span>符号，可以实时获取最新的数据</li>
                    <li><span class="main-font-color">采购概览、销售概览、杂项概览、采购退货概览、销售退货概览</span>显示的数据，默认显示当前月的数据</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E8%B4%A2%E5%8A%A1%E9%A2%84%E8%A7%88%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="2">
          <strong>进销结算</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="2-1">
                <strong>采购结算</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示采购相关流水信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据来源于采购，采购单审核通过后，将生成采购流水单，记录采购费用</li>
                    <li>点击<span class="main-font-color">结算</span>按钮，可以将状态由<span class="main-font-color">记账待结算</span>改成<span class="main-font-color">记账已结算</span></li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%87%87%E8%B4%AD%E7%BB%93%E7%AE%97%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="2-2">
                <strong>销售结算</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示销售相关流水信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据来源于销售，销售单审核通过后，将生成销售流水单，记录销售费用</li>
                    <li>点击<span class="main-font-color">结算</span>按钮，可以将状态由<span class="main-font-color">记账待结算</span>改成<span class="main-font-color">记账已结算</span></li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%94%80%E5%94%AE%E7%BB%93%E7%AE%97%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="3">
          <strong>额外结算</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="3-1">
                <strong>杂项结算</strong>
                <div slot="content">
                  <h3 class="mt-10">1.杂项结算，方便用户记录额外开销和收入</h3>
                  <ul class="ml-20 mt-5">
                    <li>用户通过该页面记录不同的开销和支出，方便记录公司开支</li>
                    <li>该页面用到的分类，需要在综合设置页面下的财务设置中的流水类别进行配置，方便区分不同的流水</li>
                    <li>杂项流水生效后，将无法更改。点击<span class="main-font-color">生效</span>按钮时，请注意填写数据是否异常</li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E6%9D%82%E9%A1%B9%E7%BB%93%E7%AE%97%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>

        <Panel name="4">
          <strong>退货结算</strong>
          <div slot="content">
            <Collapse v-model="son_module">
              <Panel name="4-1">
                <strong>采购退货结算</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示采购退货相关流水信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据来源于采购退货，采购单退货后，将生成采购退货流水单，记录采购退货相关费用</li>
                    <li>点击<span class="main-font-color">结算</span>按钮，可以将状态由<span class="main-font-color">记账待结算</span>改成<span class="main-font-color">记账已结算</span></li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%87%87%E8%B4%AD%E9%80%80%E8%B4%A7%E7%BB%93%E7%AE%97%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>

              <Panel name="2-2">
                <strong>销售退货结算</strong>
                <div slot="content">
                  <h3 class="mt-10">1.展示销售退货相关流水信息</h3>
                  <ul class="ml-20 mt-5">
                    <li>该列表的数据来源于销售退货，销售单退货后后，将生成销售退货流水单，记录销售退货相关费用</li>
                    <li>点击<span class="main-font-color">结算</span>按钮，可以将状态由<span class="main-font-color">记账待结算</span>改成<span class="main-font-color">记账已结算</span></li>
                  </ul>
                  <p>
                    <img width="660" src="http://xzlanshan.oss-cn-shanghai.aliyuncs.com/house_manage_pre/common/%E9%94%80%E5%94%AE%E9%80%80%E8%B4%A7%E7%BB%93%E7%AE%97%E9%A1%B5%E9%9D%A2.png" alt="">
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Panel>
      </Collapse>

    </div>
  </div>
</template>

<script>
export default {
  name: "FinancialCenter",
  data() {
    return {
      module: '0',
      son_module: '0',
    }
  }
}
</script>

<style scoped>
#desc-body li {
  line-height: 26px;
}
</style>
<style>
#financial-center-desc .ivu-collapse, #financial-center-desc .ivu-collapse > .ivu-collapse-item {
  border: 0;
}

#financial-center-desc .ivu-collapse > .ivu-collapse-item.ivu-collapse-item-active > .ivu-collapse-header {
  border: 0;
}

#financial-center-desc .ivu-collapse > .ivu-collapse-item > .ivu-collapse-header {
  height: 46px;
  line-height: 46px;
  color: #2775ff;
}
</style>

